/* eslint-disable vue/no-unused-components */ /* eslint-disable
vue/no-unused-components */
<template>
  <v-container :style="{ padding: '50px' }">
    <v-row class="text-center pb-0 text-h6 font-weight-bold">
      {{ $t('jumbledWordGameInstruction') }}
    </v-row>
    <v-row class="text-center pb-0 text-h4 font-weight-bold">
      {{ $t('Score') }}: {{ getScore() }}
    </v-row>
    <v-row class="text-center pb-0 text-h6 font-weight-bold">
      <v-col :class="levelSelected(1)">
        <a @click="selectLevel(1)">{{ $t('Level') }} 1</a>
      </v-col>
      <v-col :class="levelSelected(2)">
        <a @click="selectLevel(2)">{{ $t('Level') }} 2</a>
      </v-col>
      <v-col :class="levelSelected(3)">
        <a @click="selectLevel(3)">{{ $t('Level') }} 3</a>
      </v-col>
    </v-row>
    <v-row
      class="text-center ml-1 mr-1"
      :style="[
        w.shuffledWord.join('') === w.actualWord
          ? { border: '1px solid black' }
          : { border: 'none' }
      ]"
      v-for="(w, i) in seperatedWordList"
      :key="w.actualWord + i"
    >
      <draggable
        :list="w.shuffledWord"
        :disabled="!enabled"
        class="row wrap justify-space-around ma-0 pa-0"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="endDragging()"
      >
        <v-col
          v-for="(c, j) in w.shuffledWord"
          :key="j"
          class="letter row-v"
          :style="[
            w.shuffledWord.join('') === w.actualWord
              ? { background: 'green' }
              : { background: 'var(--v-primary-base)' }
          ]"
        >
          {{ c }}
        </v-col>
      </draggable>
      <v-col
        cols="12"
        class="ma-0 pa-0"
        v-if="w.shuffledWord.join('') === w.actualWord"
        :style="{ fontSize: '15px' }"
      >
        {{ $store.state.selectedLanguage === 'eng' ? w.english : w.hindi }}
      </v-col>
    </v-row>
    <GoBackButton />
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import Utils from '../util/Utils.js'
import GoBackButton from './GoBackButton'

export default {
  name: 'HelloWorld',
  components: {
    draggable,
    GoBackButton
  },
  created: function () {
    this.selectLevel(1)
  },
  methods: {
    camelize(str) {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
          return chr.toUpperCase()
        })
    },
    levelSelected(level) {
      return this.selectedLevel === level ? 'selectedLevel' : 'notSelectedLevel'
    },
    getScore() {
      let currentScore = 0
      this.seperatedWordList.forEach((w, i) => {
        if (w.shuffledWord.join('') === w.actualWord) {
          currentScore++
        }
      })
      return currentScore + '/' + this.seperatedWordList.length
    },
    selectLevel(level) {
      this.selectedLevel = level
      this.seperatedWordList = []
      const words = Object.keys(this.$store.state.wordList['level' + level])
      words.forEach((w, i) => {
        let word = this.gujaratiWordSeperator(w.split(''))
        word = this.gujaratiWordSeperator(word)

        if (word.length > 1) {
          // console.log(word)

          const shuffledWord = this.shuffle(word)
          // while (shuffledWord === word) {
          //   shuffledWord = this.shuffle(word)
          // }
          this.seperatedWordList.push({
            shuffledWord: shuffledWord,
            actualWord: w,
            ...this.$store.state.wordList['level' + level][w]
          })
        }
      })
    },
    matched(word, i) {
      // console.log(this.seperatedWordList[i].join(''))

      return word.shuffledWord.join('') === this.seperatedWordList[i].actualWord
    },
    checkMove(e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex)
    },
    endDragging(e) {
      this.dragging = false
    },
    shuffle(array) {
      const tempArray = [...array]
      let currentIndex = array.length
      let randomIndex

      if (array.length === 2) {
        this.swap(array, 0, 1)
      } else {
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex -= 1

          this.swap(array, currentIndex, randomIndex)
        }
      }
      if (tempArray.join('') === array.join('')) {
        this.swap(array, 0, 1)
      }
      if (tempArray.join('') === array.join('')) {
        this.swap(array, 1, 2)
      }
      return array
    },
    swap(array, from, to) {
      const temporaryValue = array[from]
      array[from] = array[to]
      array[to] = temporaryValue
    },
    gujaratiWordSeperator: Utils.gujaratiWordSeperator
  },
  data: () => ({
    enabled: true,
    word: [],
    selectedLevel: 1,
    seperatedWordList: [],
    dragging: false
  })
}
</script>
<style>
.custom-border {
  border: 1px solid black;
}
.correctWord {
  font: normal 60px 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
}

.letter {
  padding: 1px;
  margin: 5px;
  text-shadow: 0 1px 1px #eee;
  font: normal 40px 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
  color: white;
}
.selectedLevel {
  text-decoration: underline;
}
.notSelectedLevel {
  text-decoration: none;
}
</style>
